import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import Swal from "sweetalert2";
import * as yup from 'yup';
import { useFormik } from "formik";

const validationSchema = yup.object({
  batchId: yup.number("Please select batch").required("Batch is required"),
  userId: yup.number("Please select user").required("User is required"),
  amount: yup.string("Enter the amount").required("Amount is required"),
  enrollmentDate: yup.string("Enter the start date").required("Start Date is required"),
  paymentId: yup.string("Enter the payment id").required("Payment ID is required")
})

export const AddEnrollment = ({type, show, setShow, editId, setEditId, getEnrollments, ...rest}) => {
  const [batches, setBatches] = useState([]);
  const [users, setUsers] = useState([]);

  const handleClose = () => {
    formik.resetForm();
    setShow(false);
  }

  const initialValues = {
    id: editId,
    batchId: null,
    userId: null,
    amount: null,
    enrollmentDate: null,    
    paymentId: null
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: values => {
      handleSubmit(values);
    }
  });

  const handleSubmit = (values) =>{
    if(editId > 0){
      axios.put(`api/enrollments/update`, values).then(res => {                
        setEditId(0)
        setShow(false)
        if(res.data.success){
            Swal.fire(res.data.message, '', 'success').then(result => {
              getEnrollments();
            })
        } 
        else{
            Swal.fire(res.data.message, '', 'error')
        }
      }).catch((error) => {
        setShow(false)
        Swal.fire(error.response.data.message, '', 'error')
      });
    }
    else{
      axios.post(`api/enrollments/create`, values).then(res => {
        setShow(false)
        if(res.data.success){
          Swal.fire(res.data.message, '', 'success').then(result => {
            formik.resetForm();
            getEnrollments();
          })                
        } 
        else{
          Swal.fire(res.data.message, '', 'error')
        }
      }).catch((error) => {
        setShow(false)
        Swal.fire(error.response.data.message, '', 'error')
      });
    }        
  }

  useEffect(() => {
    getBatches()
    getUsers()
    if(editId > 0){
      axios.get(`api/enrollment?id=${editId}`).then(res => {
        if(res.data.success){
          formik.setValues(res.data.data)
        }
      })
    }
    else{
      formik.resetForm();
    }      
  },[show]);

  const getBatches = () => {
    axios.get(`/api/batches?search=null&status=Active`)
    .then(function (response) {
      let result = response.data;
      if(result.success){
        setBatches(result.data)
      }
    })
  }

  const getUsers = () => {
    axios.get(`/api/users?search=null&role=User&status=Active`)
    .then(function (response) {
      let result = response.data;
      if(result.success){
        setUsers(result.data)
      }
    })
  }

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className="modal-title Rg-Modal-title" id="exampleModalLabel1">
            {type} Enrollment
          </h5>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Batch</label>
                <select
                  name="batchId"
                  className="form-select"
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.batchId}
                >
                  <option>Select Batch</option>
                  {
                    batches.map((batch) => {
                      return (
                        <option value={batch.id}>{batch.name}</option>
                      )
                    })
                  }
                </select>
                {formik.touched.batchId && formik.errors.batchId && (<div className="text-danger">{formik.errors.batchId}</div>)}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">User</label>
                <select
                  name="userId"
                  className="form-select"
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.userId}
                >
                  <option>Select User</option>
                  {
                    users.map((user) => {
                      return (
                        <option value={user.id}>{user.name}</option>
                      )
                    })
                  }
                </select>
                {formik.touched.userId && formik.errors.userId && (<div className="text-danger">{formik.errors.userId}</div>)}
              </div>
            </div>          
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Amount</label>
                <input
                  type="text"
                  className="form-control"
                  name="amount"
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.amount}
                />
                {formik.touched.amount && formik.errors.amount && (<div className="text-danger">{formik.errors.amount}</div>)}
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label class="form-label">
                  Enrollment Date
                </label>
                <input
                  type="date"
                  class="form-control"
                  name="enrollmentDate"
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.enrollmentDate}
                />
                {formik.touched.enrollmentDate && formik.errors.enrollmentDate && (<div className="text-danger">{formik.errors.enrollmentDate}</div>)}
              </div>
            </div>            
          </div>
          <div className="row">            
            <div class="col-md-6">
              <div class="mb-3">
                <label class="form-label">
                  Payment ID
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="paymentId"
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.paymentId}
                />
                {formik.touched.paymentId && formik.errors.paymentId && (<div className="text-danger">{formik.errors.paymentId}</div>)}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-outline-secondary" onClick={handleClose}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            {type === 'Add' ? 'Add' : 'Save'}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}