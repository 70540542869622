import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import Swal from "sweetalert2";
import * as yup from 'yup';
import { useFormik } from "formik";

const validationSchema = yup.object({
  episodeId: yup.string("Please select episode").required("Episode is required"),
  question: yup.string("Enter the question").required("Question is required"),
  answer: yup.string("Enter the answer").required("Answer is required")
})

export const AddFaq = ({type, show, setShow, editId, setEditId, getFaqs, ...rest}) => {
  const [episodes, setEpisodes] = useState([]);

  const handleClose = () => {
    formik.resetForm();
    setShow(false);
  }

  const initialValues = {
    id: editId,
    episodeId: null,
    question: '',
    answer: ''
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: values => {
      handleSubmit(values);
    }
  });

  const handleSubmit = (values) =>{
    if(editId > 0){
      axios.put(`api/faqs/update`, values).then(res => {                
        setEditId(0)
        setShow(false)
        if(res.data.success){
            Swal.fire(res.data.message, '', 'success').then(result => {
              getFaqs();
            })
        } 
        else{
            Swal.fire(res.data.message, '', 'error')
        }
      }).catch((error) => {
        setShow(false)
        Swal.fire(error.response.data.message, '', 'error')
      });
    }
    else{
      axios.post(`api/faqs/create`, values).then(res => {
        setShow(false)
        if(res.data.success){
          Swal.fire(res.data.message, '', 'success').then(result => {
            formik.resetForm();
            getFaqs();
          })                
        } 
        else{
          Swal.fire(res.data.message, '', 'error')
        }
      }).catch((error) => {
        setShow(false)
        Swal.fire(error.response.data.message, '', 'error')
      });
    }        
  }

  useEffect(() => {
    getEpisodes()
    if(editId > 0){
      axios.get(`api/faq?id=${editId}`).then(res => {
        if(res.data.success){
          formik.setValues(res.data.data)
          // formik.setFieldValue('id', res.data.data.id)
          // formik.setFieldValue('name', res.data.data.name)
        }
      })
    }
    else{
      formik.resetForm();
    }      
  },[show]);

  const getEpisodes = () => {
    axios.get(`/api/episodes?status=Active`)
    .then(function (response) {
      let result = response.data;
      if(result.success){
        setEpisodes(result.data)
      }
    })
  }

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className="modal-title Rg-Modal-title" id="exampleModalLabel1">
            {type} Episode
          </h5>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <label className="form-label">Episode</label>
                <select
                  name="episodeId"
                  className="form-select"
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.episodeId}
                >
                  <option>Select Episode</option>
                  {
                    episodes.map((episode) => {
                      return (
                        <option value={episode.id}>{episode.name}</option>
                      )
                    })
                  }
                </select>
                {formik.touched.episodeId && formik.errors.episodeId && (<div className="text-danger">{formik.errors.episodeId}</div>)}
              </div>
            </div>           
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <label className="form-label">Question</label>
                <textarea
                  name="question"
                  className="form-control"
                  style={{ height: "50px" }}
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.question}
                ></textarea>
                {formik.touched.question && formik.errors.question && (<div className="text-danger">{formik.errors.question}</div>)}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <label className="form-label">Answer</label>
                <textarea
                  name="answer"
                  className="form-control"
                  style={{ height: "50px" }}
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.answer}
                ></textarea>
                {formik.touched.answer && formik.errors.answer && (<div className="text-danger">{formik.errors.answer}</div>)}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-outline-secondary" onClick={handleClose}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            {type === 'Add' ? 'Add' : 'Save'}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}