import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import Swal from "sweetalert2";
import * as yup from 'yup';
import { useFormik } from "formik";
import AWS from "aws-sdk";

const validationSchema = yup.object({
  name: yup.string("Enter the course name").required("Course Name is required"),
  subjectId: yup.number("Please select subject").required("Subject is required"),
  description: yup.string("Enter the course description").required("Course Description is required"),
  duration: yup.string("Enter the course duration").required("Course Duration is required"),
})

export const AddCourse = ({type, show, setShow, editId, setEditId, getCourses, ...rest}) => {
  const [subjects, setSubjects] = useState([]);
  const [isFileUploading, setIsFileUploading] = useState(false);

  const handleClose = () => {
    formik.resetForm();
    setShow(false);
  }

  const initialValues = {
    id: editId,
    subjectId: null,
    name: '',
    description: '',
    image: null,
    duration: 1
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: values => {
      handleSubmit(values);
    }
  });

  const handleSubmit = (values) =>{
    if(editId > 0){
      axios.put(`api/courses/update`, values).then(res => {                
        setEditId(0)
        setShow(false)
        if(res.data.success){
            Swal.fire(res.data.message, '', 'success').then(result => {
              getCourses();
            })
        } 
        else{
            Swal.fire(res.data.message, '', 'error')
        }
      }).catch((error) => {
        setShow(false)
        Swal.fire(error.response.data.message, '', 'error')
      });
    }
    else{
      axios.post(`api/courses/create`, values).then(res => {
        setShow(false)
        if(res.data.success){
          Swal.fire(res.data.message, '', 'success').then(result => {
            formik.resetForm();
            getCourses();
          })                
        } 
        else{
          Swal.fire(res.data.message, '', 'error')
        }
      }).catch((error) => {
        setShow(false)
        Swal.fire(error.response.data.message, '', 'error')
      });
    }        
  }

  useEffect(() => {
    getSubjects()
    if(editId > 0){
      axios.get(`api/course?id=${editId}`).then(res => {
        if(res.data.success){
          formik.setValues(res.data.data)
          // formik.setFieldValue('id', res.data.data.id)
          // formik.setFieldValue('name', res.data.data.name)
        }
      })
    }
    else{
      formik.resetForm();
    }      
  },[show]);

  const getSubjects = () => {
    axios.get(`/api/subjects?status=Active`)
    .then(function (response) {
      let result = response.data;
      if(result.success){
        setSubjects(result.data)
      }
    })
  }

  const handleFileChange = async (e) => {
    setIsFileUploading(true)
    const file = e.target.files[0];

    AWS.config.update({
      accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    });

    const s3 = new AWS.S3({
      params: { Bucket: process.env.REACT_APP_S3_BUCKET_NAME },
      region: process.env.REACT_APP_S3_REGION,
    });

    const params = {
      Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      Key: file.name,
      Body: file,
    };

    var upload = s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        console.log(
          "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
        );
      })
      .promise();

    await upload.then((resp) => {
      formik.setFieldValue('image', file.name)
      setIsFileUploading(false)
    });
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className="modal-title Rg-Modal-title" id="exampleModalLabel1">
            {type} Course
          </h5>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Subject</label>
                <select
                  name="subjectId"
                  className="form-select"
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.subjectId}
                >
                  <option>Select Subject</option>
                  {
                    subjects.map((subject) => {
                      return (
                        <option value={subject.id}>{subject.name}</option>
                      )
                    })
                  }
                </select>
                {formik.touched.subjectId && formik.errors.subjectId && (<div className="text-danger">{formik.errors.subjectId}</div>)}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Course Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name && (<div className="text-danger">{formik.errors.name}</div>)}
              </div>
            </div>
            
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Course Duration (Days)</label>
                <input
                  type="number"
                  className="form-control"
                  name="duration"
                  min={1}
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.duration}
                />
                {formik.touched.duration && formik.errors.duration && (<div className="text-danger">{formik.errors.duration}</div>)}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Course Image</label>
                <input
                  type="file"
                  className="form-control"
                  name="image"
                  onChange={handleFileChange}
                />
                
              </div>
            </div>
          </div>

          <div className="row">
            
            <div className="col-md-12">
              <div className="mb-3">
                <label className="form-label">Course Description</label>
                <textarea
                  name="description"
                  className="form-control"
                  style={{ height: "50px" }}
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                ></textarea>
                {formik.touched.description && formik.errors.description && (<div className="text-danger">{formik.errors.description}</div>)}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-outline-secondary" onClick={handleClose}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary" disabled={isFileUploading}>
            {type === 'Add' ? 'Add' : 'Save'}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}