import React, { useEffect, useState } from "react";
import Topbar from "../elements/Topbar";
import { Layout } from "../layout/Layout";
import { AddFaq } from "./AddFaq";
import axios from "axios";
import Swal from "sweetalert2";
import ShowPerPageRecords from "../elements/ShowPerPageRecords";
import Pagination from "../elements/Pagination";

function Faqs() {
  const [faqs, setFaqs] = useState([]);
  const [editId, setEditId] = useState(0);
  const [popupType, setPopupType] = useState('Add');
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState();
  const [limit, setLimit] = useState(10);
  const [searchText, setSearchText] = useState(null);

  const handleAdd = () => {    
    setEditId(0);
    setPopupType('Add');
    setShow(true);
  }
  const handleEdit = (id) => {
    setEditId(id);
    setPopupType('Edit');
    setShow(true);
  }

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure you want to delete?',
      showCancelButton: true,
      confirmButtonColor: '#45a7c4',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) { 
        axios.delete(`/api/faqs/delete?id=${id}`).then((res) =>{
          if(res.data.success){                             
            Swal.fire('Deleted!', '', 'success')
            getFaqs();
          } 
        }).catch((error) => {
          console.log(error);                     
          Swal.fire(error.message, '', 'info')           
        });
      } else if (result.isDenied) {
        Swal.fire('Cancelled!', '', 'info')
      }
    }) 
  }

  useEffect(() => {
    getFaqs()
  }, [page, limit, searchText])

  const getFaqs = () => {
    axios.get(`/api/faqs?search=${searchText}&page=${page}&limit=${limit}`)
    .then(function (response) {
      let result = response.data;
      if(result.success){
        setFaqs(result.data)
        setPage(result.page)
        setTotalPages(result.totalPages)
        setTotalRecords(result.totalRecords)
      }
    })
  }

  const handleStatus = (e, id) => { 
    var status = e.target.checked === true ? 'Active' : 'Inactive';
    axios.put(`api/faqs/updateStatus?id=${id}`, {status}).then(res => {                
    })
    .catch(err => {
    })
  }

  const handleSearch = (e) => {
    let value = e.target.value;
    setSearchText(value)
  }

  return (
    <Layout>
      <div className="layout-page">
        <div className="content-wrapper">
          <Topbar title="Faqs"></Topbar>
          <div className="container-xxl flex-grow-1 container-p-y">
            <div className="card">
              <div className="card-header">
                <div className="row d-flex align-items-center">
                  <div className="col-md-3">
                    <h4 className="main-title ">Faqs</h4>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 text-end">
                    <button type="button" className="btn btn-primary" onClick={handleAdd}>
                      <span className="bi bi-plus-lg"></span>&nbsp; Add Faq
                    </button>
                  </div>
                </div>
              </div>
              <div className="container-fluid">
                <div className="border-1 mb-4">
                  <div className="row paginationRj">
                    <div className="col-md-10 mt-3">
                      <ShowPerPageRecords limit={limit} setLimit={setLimit} />
                    </div>
                    <div className="col-md-2 mt-3">
                      <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" onChange={(e)=>handleSearch(e)}/>
                    </div>
                    <div className="table-responsive">
                      <table className="table" id="customer_table">
                        <thead>
                          <tr className="bg-color">
                            <th>Episode</th>
                            <th>Question</th>
                            <th>Answer</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                            {
                              faqs.map((faq) => {
                                return (
                                  <tr>
                                    <td>{faq.episode.name}</td>
                                    <td>{faq.question}</td>
                                    <td>{faq.answer}</td>
                                    <td>
                                      <div class="form-check form-switch mb-2">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          defaultChecked={faq.status === 'Active' ? true : false}
                                          onChange={(e) => handleStatus(e, faq.id)}
                                        />
                                      </div>
                                    </td>
                                    <td style={{width: "12%"}}>
                                      <span className="bg-theme" onClick={() => handleEdit(faq.id)}>
                                        <i class="bi bi-pencil"></i>
                                      </span>
                                      <span className="bg-trash" onClick={() => handleDelete(faq.id)}>
                                        <i class="bi bi-trash"></i>
                                      </span>
                                    </td>
                                  </tr>
                                )
                              })
                            }
                        </tbody>
                      </table>
                    </div>
                    <Pagination page={page} setPage={setPage} totalPages={totalPages} totalRecords={totalRecords} limit={limit}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddFaq type={popupType} show={show} setShow={setShow} editId={editId} setEditId={setEditId} getFaqs={getFaqs}/>
    </Layout>
  );
}

export default Faqs;
