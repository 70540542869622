import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import Swal from "sweetalert2";
import * as yup from 'yup';
import { useFormik } from "formik";
var moment = require('moment');

const validationSchema = yup.object({
  name: yup.string("Enter the name").required("Name is required"),
  phone: yup.string("Enter the phone number").required("Phone Number is required")
})

export const AddUser = ({type, show, setShow, editId, setEditId, getUsers, ...rest}) => {

  const handleClose = () => {
    formik.resetForm();
    setShow(false);
  }

  const initialValues = {
    id: editId,
    name: '',
    dateOfBirth: null,
    email: null,
    phone: null,    
    city: null,
    address: null,
    role: 'User'
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: values => {
      handleSubmit(values);
    }
  });

  const handleSubmit = (values) =>{
    if(editId > 0){
      axios.put(`api/users/update`, values).then(res => {                
        setEditId(0)
        setShow(false)
        if(res.data.success){
            Swal.fire(res.data.message, '', 'success').then(result => {
              getUsers();
            })
        } 
        else{
            Swal.fire(res.data.message, '', 'error')
        }
      }).catch((error) => {
        setShow(false)
        Swal.fire(error.response.data.message, '', 'error')
      });
    }
    else{
      axios.post(`api/users/create`, values).then(res => {
        setShow(false)
        if(res.data.success){
          Swal.fire(res.data.message, '', 'success').then(result => {
            formik.resetForm();
            getUsers();
          })                
        } 
        else{
          Swal.fire(res.data.message, '', 'error')
        }
      }).catch((error) => {
        setShow(false)
        Swal.fire(error.response.data.message, '', 'error')
      });
    }        
  }

  useEffect(() => {
    if(editId > 0){
      axios.get(`api/user?id=${editId}`).then(res => {
        if(res.data.success){
          formik.setValues(res.data.data)
        }
      })
    }
    else{
      formik.resetForm();
    }      
  },[show]);

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className="modal-title Rg-Modal-title" id="exampleModalLabel1">
            {type} User
          </h5>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name && (<div className="text-danger">{formik.errors.name}</div>)}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Phone Number</label>
                <input
                  type="number"
                  className="form-control"
                  name="phone"
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                />
                {formik.touched.phone && formik.errors.phone && (<div className="text-danger">{formik.errors.phone}</div>)}
              </div>
            </div>            
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  min={1}
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {/* {formik.touched.email && formik.errors.email && (<div className="text-danger">{formik.errors.email}</div>)} */}
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label class="form-label">
                  Date of Birth
                </label>
                <input
                  type="date"
                  class="form-control"
                  name="dateOfBirth"
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.dateOfBirth}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">City</label>
                <input
                  type="text"
                  className="form-control"
                  name="city"
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.city}
                />
               </div>
            </div>        
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Address</label>
                <textarea
                  name="address"
                  className="form-control"
                  style={{ height: "20px" }}
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                ></textarea>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-outline-secondary" onClick={handleClose}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            {type === 'Add' ? 'Add' : 'Save'}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}