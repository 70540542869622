export default function Pagination({page, setPage, totalPages, totalRecords, limit}){
    const pages = Array.from({ length: totalPages }, (_, index) => index + 1);

    return (
        <>
        <div className="col-md-6 fs_12 mt-3 form-label">Showing {page === 1 ? 1 : ((page - 1) * limit) + 1} to {page === totalPages ? totalRecords : page * limit} of {totalRecords} entries</div>
        <div className="col-md-6 mt-3">
            <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-end mb-0">
                    <li className={page === 1 ? "page-item disabled pe-none" : "page-item cursor-pointer"} onClick={() => setPage(page-1)}>
                        <span className="page-link">Previous</span>
                    </li>
                    {
                        pages.map(number => (
                            <li className={page === number ? "page-item active" : "page-item cursor-pointer"}><span className="page-link" onClick={() => setPage(number)}>{number}</span></li>
                        ))
                    }
                    <li className={page === totalPages ? "page-item disabled pe-none" : "page-item cursor-pointer"} onClick={() => setPage(page+1)}>
                        <span className="page-link">Next</span>
                    </li>
                </ul>
            </nav>
        </div>
        </>
    )
}