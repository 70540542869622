import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import Swal from "sweetalert2";
import * as yup from 'yup';
import { useFormik } from "formik";
var moment = require('moment');

const validationSchema = yup.object({
  name: yup.string("Enter the name").required("Name is required"),
  courseId: yup.number("Please select course").required("Course is required"),
  price: yup.string("Enter the price").required("Price is required"),
  seats: yup.number("Enter the no of seats").required("Seats is required"),
  startDate: yup.string("Enter the start date").required("Start Date is required"),
  startTime: yup.string("Enter the start time").required("Start Date is required"),
  whatsappGroupUrl: yup.string("Enter the whatsapp group url").required("Whatsapp group url is required")
})

export const AddBatch = ({type, show, setShow, editId, setEditId, getBatches, ...rest}) => {
  const [courses, setCourses] = useState([]);

  const handleClose = () => {
    formik.resetForm();
    setShow(false);
  }

  const initialValues = {
    id: editId,
    name: '',
    courseId: null,
    price: null,
    seats: null,
    startDate: null,    
    startTime: null,
    availableForHours: null,
    whatsappGroupUrl: null
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: values => {
      handleSubmit(values);
    }
  });

  const handleSubmit = (values) =>{
    if(editId > 0){
      axios.put(`api/batches/update`, values).then(res => {                
        setEditId(0)
        setShow(false)
        if(res.data.success){
            Swal.fire(res.data.message, '', 'success').then(result => {
              getBatches();
            })
        } 
        else{
            Swal.fire(res.data.message, '', 'error')
        }
      }).catch((error) => {
        setShow(false)
        Swal.fire(error.response.data.message, '', 'error')
      });
    }
    else{
      axios.post(`api/batches/create`, values).then(res => {
        setShow(false)
        if(res.data.success){
          Swal.fire(res.data.message, '', 'success').then(result => {
            formik.resetForm();
            getBatches();
          })                
        } 
        else{
          Swal.fire(res.data.message, '', 'error')
        }
      }).catch((error) => {
        setShow(false)
        Swal.fire(error.response.data.message, '', 'error')
      });
    }        
  }

  useEffect(() => {
    getCourses()
    if(editId > 0){
      axios.get(`api/batch?id=${editId}`).then(res => {
        if(res.data.success){
          formik.setValues(res.data.data)
        }
      })
    }
    else{
      formik.resetForm();
    }      
  },[show]);

  const getCourses = () => {
    axios.get(`/api/courses?status=Active`)
    .then(function (response) {
      let result = response.data;
      if(result.success){
        setCourses(result.data)
      }
    })
  }

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className="modal-title Rg-Modal-title" id="exampleModalLabel1">
            {type} Batch
          </h5>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Course</label>
                <select
                  name="courseId"
                  className="form-select"
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.courseId}
                >
                  <option>Select Course</option>
                  {
                    courses.map((course) => {
                      return (
                        <option value={course.id}>{course.name}</option>
                      )
                    })
                  }
                </select>
                {formik.touched.courseId && formik.errors.courseId && (<div className="text-danger">{formik.errors.courseId}</div>)}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name && (<div className="text-danger">{formik.errors.name}</div>)}
              </div>
            </div>            
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Price</label>
                <input
                  type="text"
                  className="form-control"
                  name="price"
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.price}
                />
                {formik.touched.price && formik.errors.price && (<div className="text-danger">{formik.errors.price}</div>)}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Seats</label>
                <input
                  type="number"
                  className="form-control"
                  name="seats"
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.seats}
                />
                {formik.touched.seats && formik.errors.seats && (<div className="text-danger">{formik.errors.seats}</div>)}
              </div>
            </div>
            
          </div>
          <div className="row">
            <div class="col-md-6">
              <div class="mb-3">
                <label class="form-label">
                  Start Date
                </label>
                <input
                  type="date"
                  class="form-control"
                  name="startDate"
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.startDate}
                />
                {formik.touched.startDate && formik.errors.startDate && (<div className="text-danger">{formik.errors.startDate}</div>)}
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label class="form-label">
                  Start Time
                </label>
                <input
                  type="time"
                  class="form-control"
                  name="startTime"
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.startTime}
                />
                {formik.touched.startTime && formik.errors.startTime && (<div className="text-danger">{formik.errors.startTime}</div>)}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Available For Hours</label>
                <input
                  type="text"
                  className="form-control"
                  name="availableForHours"
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.availableForHours}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Whatsapp Group URL</label>
                <input
                  type="text"
                  className="form-control"
                  name="whatsappGroupUrl"
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.whatsappGroupUrl}
                />
                {formik.touched.whatsappGroupUrl && formik.errors.whatsappGroupUrl && (<div className="text-danger">{formik.errors.whatsappGroupUrl}</div>)}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-outline-secondary" onClick={handleClose}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            {type === 'Add' ? 'Add' : 'Save'}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}