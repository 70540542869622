import Topbar from "../elements/Topbar";
import { Layout } from "../layout/Layout";

function ChangePassword(){
    return (
        <Layout>
      <div className="layout-page">
        <div className="content-wrapper">
          <Topbar title="Change Password"></Topbar>
          <div className="container-xxl flex-grow-1 container-p-y">
            <div className="card mb-4">
                 <h5 className="card-header">Change Password</h5>
                 <hr className="my-0" />
                 <div className="card-body">
                     <form id="formAccountSettings" method="POST" onsubmit="return false">
                         <div className="row">
                             <div className="mb-3 col-md-6 form-password-toggle">
                                 <div className="d-flex justify-content-between">
                                     <label className="form-label" for="password">Old Password</label>
                                 </div>
                                 <div className="input-group input-group-merge">
                                     <input type="password" id="password" className="form-control" name="password" placeholder="············" aria-describedby="password" />
                                     <span className="input-group-text cursor-pointer"><i className="bx bx-hide"></i></span>
                                 </div>
                             </div>

                             <div className="mb-3 col-md-6 form-password-toggle">
                                 <div className="d-flex justify-content-between">
                                     <label className="form-label" for="password">New Password</label>
                                 </div>
                                 <div className="input-group input-group-merge">
                                     <input type="password" id="password" className="form-control" name="password" placeholder="············" aria-describedby="password" />
                                     <span className="input-group-text cursor-pointer"><i className="bx bx-hide"></i></span>
                                 </div>
                             </div>

                             <div className="mb-3 form-password-toggle">
                                 <div className="d-flex justify-content-between">
                                     <label className="form-label" for="password">Re Enter New Password</label>
                                 </div>
                                 <div className="input-group input-group-merge">
                                     <input type="password" id="password" className="form-control" name="password" placeholder="············" aria-describedby="password" />
                                     <span className="input-group-text cursor-pointer"><i className="bx bx-hide"></i></span>
                                 </div>
                             </div>
                         </div>
                         <div className="mt-2">
                             <button type="submit" className="btn btn-primary me-2">Save changes</button>
                             <button type="reset" className="btn btn-outline-secondary">Cancel</button>
                         </div>
                     </form>
                 </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
    )
}
export default ChangePassword;