import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { useAutocomplete } from '@mui/base/useAutocomplete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { autocompleteClasses } from '@mui/material/Autocomplete';

const Root = styled('div')(
  ({ theme }) => `
  color: ${
    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'
  };
  font-size: 14px;
  display: inline-block;
  padding: 0 80px 0 0;
`,
);

const Container = styled('div')`
  display: flex;
  align-items: center;
`;

const Label = styled('label')`
  padding: 0 8px 0 0;
  line-height: 30px;
  display: inline-block;
`;

const InputWrapper = styled('div')(
  ({ theme }) => `
  width: 500px;
  border: 1px solid ${theme.palette.mode === 'dark' ? '#434343' : '#d9d9d9'};
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
  }

  &.focused {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
    color: ${
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'
    };
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`,
);

function Tag(props) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const StyledTag = styled(Tag)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: ${
    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.08)' : '#fafafa'
  };
  border: 1px solid ${theme.palette.mode === 'dark' ? '#303030' : '#e8e8e8'};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`,
);

const Listbox = styled('ul')(
  ({ theme }) => `
  width: 300px;
  margin: 2px 75px 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === 'dark' ? '#2b2b2b' : '#edebeb'};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`,
);

export default function MultiSelect({optionsList, formik, ...rest}) {
  const [value, setValue] = useState(formik.values.resource_episodes ? formik.values.resource_episodes.map(resource_episode => ({id: resource_episode.episodeId, name: resource_episode.resource_episode.name})) : []);

  useEffect(() => {
    // setValue(formik.values.resource_episodes.map(resource_episode => ({id: resource_episode.episodeId, name: resource_episode.resource_episode.name})))
    // console.log("value", value)
    // console.log("formik", formik.values.resource_episodes)
    // // console.log("defaultValue", defaultValue)
    if (value.length > 0){
      setValue(value)
    }
    else if (formik.values.resource_episodes){
      // console.log("defaultValue", defaultValue)
      setValue(formik.values.resource_episodes.map(resource_episode => ({id: resource_episode.episodeId, name: resource_episode.resource_episode.name})))
    }
    
  },[formik])

  useEffect(() => {
    const selectedValues = value.map(item => item.id)
    formik.setFieldValue('episodes', selectedValues)
  }, [value])

  const isOptionEqualToValue = (option, value) => {
    if (!value) {
      return false;
    }
    return option.id === value.id && option.name === value.name;
  };
  
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: 'customized-hook-demo',
    value,
    onChange: (event, newValue) => {
      setValue(newValue)      
    },
    multiple: true,
    options: optionsList,//top100Films,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue
  });

  return (
    <Root>
      <div {...getRootProps()}>
      <Container>
        {/* <Label {...getInputLabelProps()}>Subjects:</Label> */}
        <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
          {value.map((option, index) => (
            <StyledTag 
            key={option.id}
            label={option.name} 
            // onDelete={() => {
              // const newValue = [...value];
              // newValue.splice(index, 1);
              // setValue(newValue);
            // }}
            {...getTagProps({ index })} />
          ))}
          <input {...getInputProps()} />
        </InputWrapper>
        </Container>
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li {...getOptionProps({ option, index })} 
            aria-selected={value.some(selectedOption => selectedOption.id === option.id) ? 'true' : 'false'}>
              <span>{option.name}</span>
              <CheckIcon fontSize="small" />
            </li>
          ))}
        </Listbox>
      ) : null}
    </Root>
  );
}

MultiSelect.propTypes = {
  optionsList: PropTypes.array.isRequired,
  defaultValue: PropTypes.array,
};