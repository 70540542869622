import { Link, useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import Swal from "sweetalert2";
import * as yup from 'yup';
import axios from "axios";

const validationSchema = yup.object({
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    password: yup
      .string('Enter your password')
      .required('Password is required')
});

function Login () {

    const navigate = useNavigate();
    const initialValues = {
        email: '',
        password: ''
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);            
        }
    });

    const handleSubmit = (values) => {
        axios.post('/api/user/login', values).then((res) =>{
            if(res.data.success){
                console.log("login response===",res.data.data)
                localStorage.setItem('blla-authUser', JSON.stringify(res.data.data));
                if(res.data.data.role === 'Admin'){
                    navigate('/episodes');
                }
            }
            else{
                Swal.fire(res.data.message, '', 'info')
            }
        }).catch((error) => {
            Swal.fire(error.message, '', 'info')        
        });
    }

    return (
        <div class="login-bg">
            <div class="container">
                <div class="authentication-wrapper authentication-basic">
                    <div class="row">
                        
                        {/* <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-mb-none">
                            <div class="login-img">
                                <img src="./assets/img/home-bg.png" class="" />
                            </div>
                        </div> */}
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="position-container">
                                <div class="authentication-inner">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="app-brand justify-content-center">
                                                <Link to="/users" class="app-brand-link gap-2">
                                                    <img src="./assets/img/brightlife-logo.png" alt="" height="55px" />
                                                </Link>
                                            </div>
                                            <h3 class="mb-2 text-center fw-bold">Hello Again!</h3>
                                            <p class="mb-4 text-center">Enter your credentials to access your account </p>
                                            <form id="formAuthentication" class="mb-3" onSubmit={formik.handleSubmit}>
                                                <div class="form-floating mb-3">
                                                    <input type="email" class="form-control" id="floatingInput" name="email" placeholder="Enter the email" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                    <label for="floatingInput">Email address</label>
                                                    {formik.touched.email && formik.errors.email && (
                                                        <div className="text-danger">{formik.errors.email}</div>
                                                    )}
                                                </div>
                                                <div class="form-floating mb-3">
                                                    <input type="password" class="form-control" id="floatingPassword" name="password" placeholder="Enter the password" value={formik.values.password} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                                    <label for="floatingPassword">Password</label>
                                                    {formik.touched.password && formik.errors.password && (
                                                        <div className="text-danger">{formik.errors.password}</div>
                                                    )}
                                                </div>
                                                <button type="Submit" class="btn btn-primary d-grid w-100">Login</button>
                                            </form>
                                            <div class="mb-0 d-flex d-flex align-items-center justify-content-center">                                                
                                                <div class="mb-0 text-md-end">
                                                    <Link to="/ForgotPassword"> Forgot password?</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;