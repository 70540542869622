import React from "react";
import logo from "../../logo.svg";
import { Outlet, Link, useLocation } from "react-router-dom";

function Sidebar() {
  const location = useLocation();
  return (
    <>
      <aside
        id="layout-menu"
        className="layout-menu menu-vertical menu bg-menu-theme"
      >
        <div className="app-brand demo">
          <Link to="/" className="app-brand-link">
            <span className="app-brand-logo demo">
              {/* <img src={logo} alt="" height="50px" /> */}
              <img src="./assets/img/brightlife-logo.png" alt="" height="50px" />
            </span>
          </Link>

          {/* <span
            className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none"
          >
            <i className="bx bx-chevron-left bx-sm align-middle"></i>
          </span> */}
        </div>

        <div className="menu-inner-shadow"></div>

        <ul className="menu-inner py-1">
          <li className="menu-header text-capitalize fw-bold"><span className="menu-header-text clr-theme">Content </span></li>
          <li className={location.pathname === "/episodes"? "menu-item active" : "menu-item"}>
            <Link className="menu-link" to="/episodes">
              <i className="menu-icon bi bi-camera-video-fill"></i>
              <div>Episodes</div>
            </Link>
          </li>
          <li className={location.pathname === "/courses"? "menu-item active" : "menu-item"}>
            <Link className="menu-link" to="/courses">
              <i className="menu-icon bi bi-grid-fill"></i>
              <div>Courses</div>
            </Link>
          </li>
          <li className={location.pathname === "/subjects"? "menu-item active" : "menu-item"}>
            <Link className="menu-link" to="/subjects">
              <i className="menu-icon bi bi-grid-fill"></i>
              <div>Subjects</div>
            </Link>
          </li>
          <li className={location.pathname === "/resources"? "menu-item active" : "menu-item"}>
            <Link className="menu-link" to="/resources">
              <i className="menu-icon tf-icons bi bi-book-fill"></i>
              <div>Resources</div>
            </Link>
          </li> 
          <li className={location.pathname === "/faqs"? "menu-item active" : "menu-item"}>
            <Link className="menu-link" to="/faqs">
              <i className="menu-icon tf-icons bi bi-question-octagon-fill"></i>
              <div>Faqs</div>
            </Link>
          </li>
          <li className="menu-header text-capitalize fw-bold"><span className="menu-header-text clr-theme">Participant </span></li>
           <li className={location.pathname === "/users"? "menu-item active" : "menu-item"}>
            <Link className="menu-link" to="/users">
              <i className="menu-icon bi bi-people-fill"></i>
              <div>Users</div>
            </Link>
          </li>
          <li className={location.pathname === "/batches"? "menu-item active" : "menu-item"}>
            <Link className="menu-link" to="/batches">
              <i className="menu-icon bi bi-grid-fill"></i>
              <div>Batches</div>
            </Link>
          </li>
          <li className={location.pathname === "/enrollments"? "menu-item active" : "menu-item"}>
            <Link className="menu-link" to="/enrollments">
              <i className="menu-icon bi bi-grid-fill"></i>
              <div>Enrollments</div>
            </Link>
          </li>                   
        </ul>
        <Outlet />
      </aside>
    </>
  );
}

export default Sidebar;
