import { Link } from "react-router-dom";

function forgotPassword () {
    return (
        <div class="login-bg">
            <div class="container">
                <div class="authentication-wrapper authentication-basic">
                    <div class="row">
                        
                        {/* <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-mb-none">
                            <div class="login-img">
                                <img src="./assets/img/home-bg.png" class="" />
                            </div>
                        </div> */}
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="position-container">
                                <div class="authentication-inner">                            
                                    <div class="card">
                                        <div class="card-body">                                    
                                            <div class="app-brand justify-content-center">
                                                <Link to="/users" class="app-brand-link gap-2">
                                                    <img src="./assets/img/brightlife-logo.png" alt="" height="55px" />
                                                </Link>
                                            </div>                                  
                                            <h3 class="mb-2 text-center fw-bold">Forgot Password? 🔒</h3>
                                            <p class="mb-4 text-center">Enter your email and we'll send you instructions <br/>to reset your password</p>
                                            <form id="formAuthentication" class="mb-3" action="#" method="POST">
                                                <div class="form-floating mb-3">
                                                    <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" />
                                                    <label for="floatingInput">Email address</label>
                                                </div>                                        
                                                <Link to="/users"><button class="btn btn-primary d-grid w-100">Send Reset Link</button></Link>
                                            </form>
                                            <div class="text-center">
                                                <Link to="/" class="d-flex align-items-center justify-content-center">
                                                    <i class="bx bx-chevron-left scaleX-n1-rtl bx-sm"></i>
                                                    Back to login
                                                </Link>
                                            </div>
                                        </div>
                                    </div>                            
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default forgotPassword;