export default function ShowPerPageRecords({limit, setLimit}){
    return (
        <label className="form-label">Show
            <select className="showRj" value={limit} onChange={(e) => setLimit(e.target.value)}>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
            entries
        </label>
    )
}