import axios from 'axios';
// axios.defaults.baseURL = 'http://localhost:3001';
axios.defaults.baseURL = 'https://api.joinbrightlife.com';

axios.interceptors.request.use(
    async (config) => {
        const authUser = await localStorage.getItem('blla-authUser');
        const user = JSON.parse(authUser);
        if (user) {
            const token = user.token;
            config.headers.accessToken = token;
            if(config.url.includes('/api/users/updateProfile')){
                config.headers= {"Content-Type": "multipart/form-data",'x-access-token': token};
            }
            else{
                config.headers= {"Content-Type": "application/json",'x-access-token': token};
            }
        }
        else{
            config.headers= {"Content-Type": "application/json"};
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);