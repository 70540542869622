import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import Swal from "sweetalert2";
import * as yup from 'yup';
import { useFormik } from "formik";
import AWS from "aws-sdk";

const validationSchema = yup.object({
  title: yup.string("Enter the title").required("Title is required")
})

export const Mapping = ({show, setShow, editId, setEditId, getResources, ...rest}) => {

  const handleClose = () => {
    formik.resetForm();
    setShow(false);
  }

  const initialValues = {
    resourceId: editId,
    episodeIds: ''
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: values => {
      handleSubmit(values);
    }
  });

  const handleSubmit = (values) =>{
    axios.put(`api/resources/mapping`, values).then(res => {                
      setEditId(0)
      setShow(false)
      if(res.data.success){
          Swal.fire(res.data.message, '', 'success').then(result => {
            getResources();
          })
      } 
      else{
          Swal.fire(res.data.message, '', 'error')
      }
    }).catch((error) => {
      setShow(false)
      Swal.fire(error.response.data.message, '', 'error')
    });    
  }

  useEffect(() => {
    axios.get(`api/resource?id=${editId}`).then(res => {
      if(res.data.success){
        formik.setValues(res.data.data)
      }
    }) 
  },[show]);

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className="modal-title Rg-Modal-title" id="exampleModalLabel1">
            Resource Mapping
          </h5>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <div className="row">            
            {/* <div className="col-md-8">
              <div className="mb-3">
                <label className="form-label">Episodes</label>
                <input
                  type="text"
                  className="form-control"
                  name="epis"
                  // onChange={formik.handleChange} 
                  // onBlur={formik.handleBlur}
                  // value={formik.values.title}
                />
                {formik.touched.title && formik.errors.title && (<div className="text-danger">{formik.errors.title}</div>)}
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-3">
                <button type="submit" className="btn btn-primary">
                  Save
                </button>                
              </div>
            </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-outline-secondary" onClick={handleClose}>
            Close
          </button>
          
        </Modal.Footer>
      </form>
    </Modal>
  );
}