import React from "react";
import { Link, useNavigate } from "react-router-dom";

function Topbar({ title }) {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };
  return (
    <>
      <nav
        className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
        id="layout-navbar"
      >
        <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
          <span className="nav-item nav-link px-0 me-xl-4">
            <i className="bx bx-menu bx-sm"></i>
          </span>
        </div>
        <div
          className="navbar-nav-right d-flex align-items-center"
          id="navbar-collapse"
        >
          <div className="row d-flex align-items-center">
            <h4 className="main-title ">{title}</h4>
          </div>

          <ul className="navbar-nav flex-row align-items-center ms-auto">
            <li className="nav-item navbar-dropdown dropdown-user dropdown">
              <span
                className="nav-link dropdown-toggle cursor-pointer"
                data-bs-toggle="dropdown"
              >
                <span className="fw-bold">Admin</span>
                <i className="bx bx-chevron-down"></i>
              </span>
              <ul className="dropdown-menu dropdown-menu-end">
                <li>
                  <div className="dropdown-divider"></div>
                </li>
                <li>
                 <Link to="/change-password" className="dropdown-item">
                    <i className="bx bx-cog me-2"></i>
                    <span className="align-middle fw-bold me-2">Change Password</span>
                  </Link>
                </li>
                <li>
                  <div className="dropdown-divider"></div>
                </li>
                <li>
                  <span className="dropdown-item cursor-pointer" onClick={handleLogout}>
                    <i className="bx bx-power-off me-2"></i>
                    <span className="align-middle fw-bold ">Log Out</span>
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Topbar;
