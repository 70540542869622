/*import "./assets/vendor/fonts/boxicons.css";*/
import "./intercepter";
import { Routes, Route, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/vendor/css/core.css";
import "./assets/vendor/css/theme-default.css";
import "./assets/css/demo.css";
import "./assets/css/custom.css";
import "./assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css";
import "./assets/vendor/css/pages/page-auth.css";
import "bootstrap/dist/js/bootstrap.min.js";

import Courses from "./components/courses/Courses";
import Episodes from "./components/episodes/Episodes";
import Users from "./components/users/Users";
import Resources from "./components/Resources/Resources";
import Subjects from "./components/subjects/Subjects";
import Faqs from "./components/faqs/Faqs";
import Login from "./components/login/Login";
import ForgotPassword from "./components/forgotPassword/ForgotPassword";
import Batches from "./components/batch/Batches";
import Enrollments from "./components/enrollment/Enrollments";
import PublicRoutes from "./components/Routes/PublicRoutes";
import ProtectedRoutes from "./components/Routes/ProtectedRoutes";
import ChangePassword from "./components/ChangePassword/ChangePassword";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<PublicRoutes />}>
          <Route path="/" element={<Login />} />
          <Route path="/ForgotPassword" element={<ForgotPassword />}></Route>
        </Route>
        <Route path="/" element={<ProtectedRoutes />}>
          <Route path="/" element={<Navigate replace to="episodes" />} />
          <Route path="/episodes" element={<Episodes />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/subjects" element={<Subjects />} />          
          <Route path="/resources" element={<Resources />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/users" element={<Users />} />
          <Route path="/batches" element={<Batches />} />
          <Route path="/enrollments" element={<Enrollments />} />
          <Route path="/change-password" element={<ChangePassword />} />
        </Route>        
      </Routes>
    </>
  );
}

export default App;
