import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import Swal from "sweetalert2";
import * as yup from 'yup';
import { useFormik } from "formik";
import AWS from "aws-sdk";
import MultiSelect from "../elements/MultiSelect";

const validationSchema = yup.object({
  title: yup.string("Enter the title").required("Title is required"),
  type: yup.string("Please select type").required("Type is required"),
  link: yup.string().when(['type'], (type, linkSchema) => {
    if(type == 'URL' || type == 'Video'){
        return linkSchema.required('Link is required')
    }
    else if(type == 'PDF'){
      return linkSchema.required('Please upload pdf file')
    }
    else{
        return linkSchema.notRequired()
    }
}),
})

export const AddResource = ({type, show, setShow, editId, setEditId, getResources, ...rest}) => {
  const [episodes, setEpisodes] = useState([]);
  const [isFileUploading, setIsFileUploading] = useState(false);

  const handleClose = () => {
    formik.resetForm();
    setShow(false);
  }

  const initialValues = {
    id: editId,
    title: '',
    thumbnail: null,    
    type: null,
    link: null,
    episodes: []
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: values => {
      handleSubmit(values);
    }
  });

  const handleSubmit = (values) =>{
    if(editId > 0){
      axios.put(`api/resources/update`, values).then(res => {                
        setEditId(0)
        setShow(false)
        if(res.data.success){
            Swal.fire(res.data.message, '', 'success').then(result => {
              getResources();
            })
        } 
        else{
            Swal.fire(res.data.message, '', 'error')
        }
      }).catch((error) => {
        setShow(false)
        Swal.fire(error.response.data.message, '', 'error')
      });
    }
    else{
      axios.post(`api/resources/create`, values).then(res => {
        setShow(false)
        if(res.data.success){
          Swal.fire(res.data.message, '', 'success').then(result => {
            formik.resetForm();
            getResources();
          })                
        } 
        else{
          Swal.fire(res.data.message, '', 'error')
        }
      }).catch((error) => {
        setShow(false)
        Swal.fire(error.response.data.message, '', 'error')
      });
    }        
  }

  useEffect(() => {
    getEpisodes()
    if(editId > 0){
      axios.get(`api/resource?id=${editId}`).then(res => {
        if(res.data.success){
          formik.setValues(res.data.data)
        }
      })
    }
    else{
      formik.resetForm();
    }      
  },[show]);

  const getEpisodes = () => {
    axios.get(`/api/episodes?search=null&status=Active`)
    .then(function (response) {
      let result = response.data;
      if(result.success){
        setEpisodes(result.data)
      }
    })
  }

  const handleFileChange = async (e, type) => {
    setIsFileUploading(true)
    const file = e.target.files[0];

    AWS.config.update({
      accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    });

    const s3 = new AWS.S3({
      params: { Bucket: process.env.REACT_APP_S3_BUCKET_NAME },
      region: process.env.REACT_APP_S3_REGION,
    });

    const params = {
      Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      Key: file.name,
      Body: file,
    };

    var upload = s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        console.log(
          "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
        );
      })
      .promise();

    await upload.then((resp) => {
      if(type === 'Image') formik.setFieldValue('thumbnail', file.name)
      if(type === 'PDF') formik.setFieldValue('link', file.name)
      setIsFileUploading(false)
    });
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className="modal-title Rg-Modal-title" id="exampleModalLabel1">
            {type} Resource
          </h5>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <div className="row">            
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Title</label>
                <input
                  type="text"
                  className="form-control"
                  name="title"
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.title}
                />
                {formik.touched.title && formik.errors.title && (<div className="text-danger">{formik.errors.title}</div>)}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Thumbnail</label>
                <input
                  type="file"
                  className="form-control"
                  name="image"
                  onChange={(e) => handleFileChange(e, 'Image')}
                />                     
              </div>
            </div>
          </div>
          <div className="row">            
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Type</label>
                <select
                  name="type"
                  className="form-select"
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.type}
                >
                  <option>Select Type</option>
                  <option value="PDF">PDF</option>
                  <option value="URL">URL</option>
                  <option value="Video">Video</option>
                </select>
                {formik.touched.type && formik.errors.type && (<div className="text-danger">{formik.errors.type}</div>)}
              </div>
            </div>
            <div className="col-md-6">
              {
                formik.values.type === 'PDF' ? 
                <div className="mb-3">
                  <label className="form-label">Upload PDF</label>
                  <input
                    type="file"
                    className="form-control"
                    name="uploadPdf"
                    onChange={(e) => handleFileChange(e, 'PDF')}
                  />
                  {formik.touched.link && formik.errors.link && (<div className="text-danger">{formik.errors.link}</div>)}          
                </div>
                : formik.values.type === 'URL' || formik.values.type === 'Video' ?
                <div className="mb-3">
                  <label className="form-label">Link</label>
                  <input
                    name="link"
                    className="form-control"
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}
                    value={formik.values.link}
                  />
                  {formik.touched.link && formik.errors.link && (<div className="text-danger">{formik.errors.link}</div>)}
                </div>
                : ''
              }
              
            </div>            
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <label className="form-label">Episodes</label>
                <MultiSelect
                  formik={formik}
                  optionsList={episodes ?
                    episodes.map(episode => ({
                      id: episode.id,
                      name: episode.name
                    }))
                  :[]} 
                  // defaultValue={formik.values.resource_episodes ? formik.values.resource_episodes.map(resource_episode => ({id: resource_episode.episodeId, name: resource_episode.resource_episode.name})) : []}
              />            
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-outline-secondary" onClick={handleClose}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary" disabled={isFileUploading}>
            {type === 'Add' ? 'Add' : 'Save'}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}