import React from 'react';
import Sidebar from '../elements/Sidebar';

export const Layout = (props) => {
    const { children } = props;
    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Sidebar></Sidebar>
                {children}
            </div>
        </div>
    )
}