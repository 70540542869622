import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import Swal from "sweetalert2";
import * as yup from 'yup';
import { useFormik } from "formik";
import AWS from "aws-sdk";

const validationSchema = yup.object({
  name: yup.string("Enter the title").required("Title is required"),
  courseId: yup.number("Please select course").required("Course is required"),
  description: yup.string("Enter the description").required("Description is required"),
  episodeDay: yup.string("Enter the episode day").required("Episode Day is required")
})

export const AddEpisode = ({type, show, setShow, editId, setEditId, getEpisodes, ...rest}) => {
  const [courses, setCourses] = useState([]);
  const [courseDays, setCourseDays] = useState(0);
  const [isFileUploading, setIsFileUploading] = useState(false);

  const handleClose = () => {
    formik.resetForm();
    setShow(false);
  }

  const initialValues = {
    id: editId,
    courseId: null,
    name: '',
    episodeDay: '',
    description: '',
    videoUrl: null,
    videoDuration: null,
    thumbnail: null    
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: values => {
      handleSubmit(values);
    }
  });

  const handleSubmit = (values) =>{
    if(editId > 0){
      axios.put(`api/episodes/update`, values).then(res => {                
        setEditId(0)
        setShow(false)
        if(res.data.success){
            Swal.fire(res.data.message, '', 'success').then(result => {
              getEpisodes();
            })
        } 
        else{
            Swal.fire(res.data.message, '', 'error')
        }
      }).catch((error) => {
        setShow(false)
        Swal.fire(error.response.data.message, '', 'error')
      });
    }
    else{
      axios.post(`api/episodes/create`, values).then(res => {
        setShow(false)
        if(res.data.success){
          Swal.fire(res.data.message, '', 'success').then(result => {
            formik.resetForm();
            getEpisodes();
          })                
        } 
        else{
          Swal.fire(res.data.message, '', 'error')
        }
      }).catch((error) => {
        setShow(false)
        Swal.fire(error.response.data.message, '', 'error')
      });
    }        
  }

  useEffect(() => {
    getCourses()
    if(editId > 0){
      axios.get(`api/episode?id=${editId}`).then(res => {
        if(res.data.success){
          formik.setValues(res.data.data)
          // formik.setFieldValue('id', res.data.data.id)
          // formik.setFieldValue('name', res.data.data.name)
        }
      })
    }
    else{
      formik.resetForm();
    }      
  },[show]);

  const getCourses = () => {
    axios.get(`/api/courses?status=Active`)
    .then(function (response) {
      let result = response.data;
      if(result.success){
        setCourses(result.data)
      }
    })
  }

  const handleCourseChange = (e) => {
    let id = e.target.value
    if(id > 0){
      formik.setFieldValue('courseId', id)
      axios.get(`api/course?id=${id}`).then(res => {
        if(res.data.success && res.data.data?.duration){
          setCourseDays(res.data.data.duration)
        }
        else{
          setCourseDays(0)
        }
      })
    }
    else{
      formik.setFieldValue('courseId', null)
      formik.setFieldValue('episodeDay', '')
      setCourseDays(0)
    }
  }

  const createEpisodeDayOptions = () => {
    console.log("dayas", courseDays)
    let options = []
    if(courseDays > 0){
      options.push(<option key="selectDay" value="">Select Episode Day</option>)
      options.push(<option key="intro" value="Intro">Intro</option>)
      for(let i = 1; courseDays >= i; i++){
        options.push(<option key={"d"+i} value={i}>{i}</option>)
      }
      options.push(<option key="end" value="End">End</option>)
    }
    else{
      options.push(<option key="end" value="End">Select Course First</option>)
    }

    return options
  }

  const handleFileChange = async (e) => {
    setIsFileUploading(true)
    const file = e.target.files[0];

    AWS.config.update({
      accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    });

    const s3 = new AWS.S3({
      params: { Bucket: process.env.REACT_APP_S3_BUCKET_NAME },
      region: process.env.REACT_APP_S3_REGION,
    });

    const params = {
      Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      Key: file.name,
      Body: file,
    };

    var upload = s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        console.log(
          "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
        );
      })
      .promise();

    await upload.then((resp) => {
      formik.setFieldValue('thumbnail', file.name)
      setIsFileUploading(false)
    });
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className="modal-title Rg-Modal-title" id="exampleModalLabel1">
            {type} Episode
          </h5>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Course</label>
                <select
                  name="courseId"
                  className="form-select"
                  onChange={handleCourseChange} 
                  onBlur={handleCourseChange}
                  value={formik.values.courseId}
                >
                  <option>Select Course</option>
                  {
                    courses.map((course) => {
                      return (
                        <option value={course.id}>{course.name}</option>
                      )
                    })
                  }
                </select>
                {formik.touched.courseId && formik.errors.courseId && (<div className="text-danger">{formik.errors.courseId}</div>)}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Title</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name && (<div className="text-danger">{formik.errors.name}</div>)}
              </div>
            </div>            
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Episode Day</label>
                <select
                  name="episodeDay"
                  className="form-select"
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.episodeDay}
                >
                  {createEpisodeDayOptions()}
                </select>
                {formik.touched.episodeDay && formik.errors.episodeDay && (<div className="text-danger">{formik.errors.episodeDay}</div>)}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Thumbnail</label>
                <input
                  type="file"
                  className="form-control"
                  name="thumbnail"
                  onChange={handleFileChange}
                />                
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <label className="form-label">Description</label>
                <textarea
                  name="description"
                  className="form-control"
                  style={{ height: "80px" }}
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                ></textarea>
                {formik.touched.description && formik.errors.description && (<div className="text-danger">{formik.errors.description}</div>)}
              </div>
            </div>
            
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <label className="form-label">Vimeo Video URL</label>
                <textarea
                  className="form-control"
                  name="videoUrl"
                  style={{ height: "20px" }}
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.videoUrl}
                ></textarea>
                {formik.touched.videoUrl && formik.errors.videoUrl && (<div className="text-danger">{formik.errors.videoUrl}</div>)}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Video Duration</label>
                <input
                  type="number"
                  className="form-control"
                  name="videoDuration"
                  min={1}
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.videoDuration}
                />
                {formik.touched.videoDuration && formik.errors.videoDuration && (<div className="text-danger">{formik.errors.videoDuration}</div>)}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Others</label>
                <input
                  type="text"
                  className="form-control"
                  name="others"
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                  value={formik.values.others}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-outline-secondary" onClick={handleClose}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary" disabled={isFileUploading}>
            {type === 'Add' ? 'Add' : 'Save'}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}